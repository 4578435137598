
















































































import {
  Component, Vue, Prop, Watch, Ref,
} from 'vue-property-decorator';
import { ServiceHook, ServiceHookUpdateModel } from '@/models/service-hook-maintenance.d';
import * as validation from '@/models/validation';
import MetadataEditor from '@/components/library-maintenance/metadata-editor.vue';
import EcTextField from 'common-components/src/components/form/ec-text-field.vue';
import EcSelect from 'common-components/src/components/form/ec-select.vue';
import { FieldList, Field } from '@/models/form';
import config from '@/config';
import { ApiError } from '../../models/hal.d';

@Component({
  components: {
    MetadataEditor,
    EcTextField,
    EcSelect,
  },
})
export default class ServiceHookEditor extends Vue {
  @Prop() value!: ServiceHook;
  @Prop() originalValue!: ServiceHook;

  @Prop({ default: () => ([]) }) apiErrors!: ApiError[];

  fieldList = new FieldList({
    name: new Field(
      '',
      'Name',
      [
        validation.required(),
      ],
    ),
    description: new Field(
      '',
      'Description',
      [
        validation.maxLength(200),
      ],
    ),
    authorisationType: new Field(
      '',
      'Authorisation Type',
      [
        validation.required(),
      ],
      [],
      'authorisation-type',
    ),
    uri: new Field(
      '',
      'Uri',
      [
        validation.required(),
        validation.uri(),
        validation.uriProtocols(config.serviceHookUriProtocols),
      ],
    ),
    headers: new Field(
      {},
      'Headers',
    ),
    'client-id': new Field(
      '',
      'Client Id',
      [
        validation.required(),
      ],
    ),
    'client-secret': new Field(
      '',
      'Client Secret',
      [
        validation.required(),
      ],
    ),
    audience: new Field(
      '',
      'Audience',
    ),
    'token-endpoint': new Field(
      '',
      'Token Endpoint',
      [
        validation.required(),
        validation.uri(),
        validation.uriProtocols(config.authEndPointUriProtocols),
      ],
    ),
    'content-type': new Field(
      '',
      'Content Type',
      [],
      [],
      'content-type',
    ),
  });

  get fields() { return this.fieldList.fields; }

  authorisationTypes = [
    { text: 'None', value: 'none' },
    { text: 'OAuth', value: 'oauth' },
  ];

  contentTypes = [
    { text: 'JSON', value: 'application/json' },
    { text: 'Url Encoded', value: 'application/x-www-form-urlencoded' },
    { text: 'Basic Auth', value: 'Authentication Scheme: Basic' },
  ];

  @Watch('value', { immediate: true })
  onValueChanged() {
    this.setModel(this.value);
  }

  @Watch('apiErrors')
  onErrorsChanged() {
    this.fieldList.addAllErrors(this.apiErrors);
  }

  @Ref() form!: HTMLFormElement

  fetch(
    callback: (serviceHook: ServiceHookUpdateModel) => void,
    validationCallback: () => void,
  ) {
    const valid = this.form.validate();
    if (!valid) {
      validationCallback();

      return;
    }

    const options: any = {
      uri: this.fields.uri.value,
      headers: this.fields.headers.value,
    };

    if (this.fields.authorisationType.value === 'oauth') {
      options.authorisation = {
        oauth: {
          'client-id': this.fields['client-id'].value,
          'client-secret': this.fields['client-secret'].value,
          audience: this.fields.audience.value,
          'token-endpoint': this.fields['token-endpoint'].value,
          'content-type': this.fields['content-type'].value,
        },
      };
    }

    const model: ServiceHookUpdateModel = {
      name: this.fields.name.value,
      type: 'http',
      description: this.fields.description.value,
      events: ['screen-complete'],
      options,
    };

    callback(model);
  }

  reset() {
    this.setModel(this.originalValue);
  }

  setModel(setter: ServiceHook) {
    const clone = JSON.parse(JSON.stringify(setter)) as ServiceHook;
    this.fields.name.value = clone.name;
    this.fields.description.value = clone.description || '';

    this.fields.authorisationType.value = 'none';

    const { uri = '', headers = {}, authorisation = {} } = { ...clone.options };

    this.fields.uri.value = uri;
    this.fields.headers.value = headers;

    if ('oauth' in authorisation) {
      this.fields.authorisationType.value = 'oauth';

      const { oauth } = authorisation;
      this.fields['client-id'].value = oauth['client-id'];
      this.fields['client-secret'].value = oauth['client-secret'];
      this.fields.audience.value = oauth.audience;
      this.fields['token-endpoint'].value = oauth['token-endpoint'];
      this.fields['content-type'].value = oauth['content-type'];
    } else {
      this.fields['client-id'].value = '';
      this.fields['client-secret'].value = '';
      this.fields.audience.value = '';
      this.fields['token-endpoint'].value = '';
      this.fields['content-type'].value = '';
    }   
  }
}
